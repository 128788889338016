<template>
  <main class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
      <div class="container">
        <!-- <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item active">Forgot Password</li>
                </ol> -->
      </div>
    </nav>

    <div class="page-content py-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-5">
            <div class="bg-white br-1 p-4 p-md-5">
              <h5 class="text-primary text-center">
                {{ $t("password_forgot_login_form") }}
              </h5>
              <p class="text-dark">{{ $t("forgot_password_text") }}</p>
              <form action="#" class="mt-1">
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group id="email-group" :label="$t('email_address') + '*'" label-for="email">
                      <b-form-input v-validParent id="email" v-model="form.email" type="text"
                        v-on:blur="validateEmail()" :state="form.emailState"
                        :placeholder="$t('email_address_login_form')" aria-describedby="email-feedback"></b-form-input>
                      <b-form-invalid-feedback id="email-feedback">
                        {{ form.emailError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- <div class="form-group">
                                            <label for="email">{{ $t('email_address') }} *</label>
                                            <input type="email" class="form-control" id="email" name="email"
                                                v-model="email" :placeholder="$t('email_address')" required />
                                        </div> -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-footer justify-content-end">
                      <button type="submit" class="btn btn-dark" @click.prevent="formSubmit">
                        <span>{{ $t("recover_my_password") }}</span>
                        <!-- <i class="icon-long-arrow-right"></i> -->
                      </button>
                    </div>
                  </div>
                  <div class="col-md-12 text-right text-md-left mt-1 mt-md-0">
                    <b-link @click="$router.go(-1)">
                      <font-awesome-icon class="text-primary" icon="fa-solid fa-angle-left" />
                      {{ $t("go_back") }}</b-link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { forgotPassword } from "@storefront/core/data-resolver/user";

export default {
  data() {
    return {
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      form: {
        email: "",
        emailState: null,
        emailError: "",
        description: "",
      },
    };
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("password_forgot") },
        { name: "keywords", content: this.$t("password_forgot") },
        { name: "description", content: this.$t("password_forgot") },
      ],
      title: this.$t("password_forgot"),
    };
  },
  methods: {
    async formSubmit() {
      if (this.validateEmail()) {
        const retval = await forgotPassword(this.form.email);
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("account"),
            text: this.$t("reset_password_email_has_been_sent"),
          };
          this.$store.dispatch("messages/sendMessage", {
            message: msg,
          });
        }
      }
    },
    validateEmail() {
      if (this.form.email == "") {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.form.emailState = true;
      return true;
    },
  },
};
</script>
